import React from "react";
// Style
import "./QRPage.scss";

// Components
import PageHero from "../../containers/PageHero/PageHero";

// QR
import QRsvg from "../../styles/img/assets/CV_QR.svg";

function QRPage() {
  return (
    <section className="QR_section">
      <PageHero>
        <h1 className="QR_h1">Here's your QR</h1>
      </PageHero>

      <div className="flexCenter QR_container">
        <img id="QR_CV" src={QRsvg} alt="CV QR code" />
        <h3>
          Scan to download <span>Alon Fabio</span>'s CV
        </h3>
      </div>
    </section>
  );
}

export default QRPage;
