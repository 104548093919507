import React, { useEffect } from "react";
// Style
import "./PDFComp.scss";

// Components
import PageHero from "../../containers/PageHero/PageHero";

// PDF
import alonCV from "../../static/pdf/exp/alonCV.pdf";

function PDFComp() {
  const downloadPDF = async () => {
    try {
      // const fileUrl =
      //   "https://drive.google.com/uc?export=download&id=1P474XytZlpC0bQJrwUgH6ite-l4tsp5N"; google pdf
      const fileUrl = alonCV;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Alon Fabio CV.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  useEffect(() => {
    downloadPDF();
  }, []);

  return (
    <section className="PDF_section ">
      <PageHero>
        <h1 className="PDF_h1">Here's your PDF</h1>
      </PageHero>

      <div className="pdf_a_container flexCenter ">
        <p onClick={() => downloadPDF()} rel="noreferrer">
          Download my CV<span>.PDF</span>{" "}
          <i className="fa-solid fa-file-arrow-down"></i>
        </p>
      </div>

      <div className="PDF_object_container">
        <object className="PDF_object" data={alonCV} type="application/pdf">
          <embed src={alonCV} type="application/pdf"></embed>
          <p>This browser does not support PDFs.</p>
        </object>
      </div>
    </section>
  );
}

export default PDFComp;
