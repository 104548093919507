import React from "react";
import "./loadAni.scss";

function LoadAni() {
  return (
    <div className="flexCenter LoadAni">
      <h1>L</h1>

      <div className="loading_dual_ring"></div>
      <h1>ading</h1>
    </div>
  );
}

export default LoadAni;
