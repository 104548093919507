const NavLogo40 = () => {
  return (
    <svg
      className={"NavLogo"}
      id="NavLogo"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="88px"
      height="40px"
      viewBox="0 0 88 40"
      enableBackground="new 0 0 493.645 226.707"
      xmlSpace="preserve"
    >
      <g id="Layer_1">
        <path d="M25.835,0h4.431l6.624,20h-3.11l-1.565-5h-7.062l-1.594,5h-4.421L25.835,0z M31.323,12l-2.55-8.07L26.118,12H31.323z" />
        <path d="M19.139,20h4.42l6.608,20h-3.063l-1.561-5h-7.066l-1.59,5h-4.429L19.139,20z M24.653,32l-2.583-8.05L19.439,32H24.653z" />
        <path d="M51,20H39V0h4v17h8V20z" />
        <path
          d="M88,20h-3.439L76.991,7.267c-0.117-0.195-0.247-0.507-0.511-0.946C76.364,6.127,76,5.824,76,5.405V20h-3V0h3.962
	l6.876,11.784C83.896,11.881,84,12.633,85,13.968V0h3V20z"
        />
        <path d="M4,32h7v-3H4v-6h9v-3H0v20h4V32z" />
        <path
          d="M34,40V20h7.619c1.314,0,2.406,0.145,3.273,0.418s1.617,0.847,2.25,1.714c0.634,0.867,0.95,1.887,0.95,3.056
	c0,1.101-0.321,2.059-0.965,2.872c-0.643,0.813-1.529,1.333-2.659,1.557c1.402,0.244,2.465,0.814,3.186,1.71
	c0.721,0.896,1.081,1.958,1.081,3.186c0,1.539-0.504,2.851-1.513,3.897c-1.008,1.048-2.56,1.59-4.654,1.59H34z M37,28h4.633
	c0.896,0,1.605-0.073,2.126-0.609c0.521-0.535,0.782-1.12,0.782-1.948c0-0.916-0.297-1.475-0.891-1.909
	C43.055,23.102,42.358,23,41.56,23H37V28z M37,37h4.896c0.984,0,1.761-0.274,2.331-0.791s0.854-1.221,0.854-2.097
	c0-0.838-0.29-1.648-0.869-2.185C43.632,31.392,42.865,31,41.911,31H37V37z"
        />
        <path d="M57,40h-4V20h4V40z" />
        <g>
          <path
            d="M63.797,21.031c-4.234-1.722-8.814-3.439-10.811-8.174c-2.068-4.905,0.076-9.939,5.104-12.059
		c4.615-1.946,10.225,0.093,12.393,5.237c1.822,4.321-0.269,8.99-1.893,12.975c4.237,1.728,8.816,3.445,10.811,8.174
		c2.095,4.968-0.074,9.945-5.099,12.063c-4.618,1.947-10.228-0.092-12.396-5.236C60.083,29.688,62.176,25.021,63.797,21.031z
		 M65.21,17.685c1.142-3.166,3.17-6.84,1.74-10.233c-1.231-2.921-4.562-4.128-7.308-2.97c-2.98,1.257-4.257,4.271-3.051,7.131
		C57.996,14.943,61.993,16.423,65.21,17.685z M72.749,35.565c2.979-1.255,4.254-4.27,3.047-7.135
		c-1.404-3.331-5.402-4.811-8.614-6.068c-1.145,3.167-3.174,6.841-1.745,10.229C66.671,35.517,70.001,36.724,72.749,35.565z"
          />
        </g>
      </g>
    </svg>
  );
};
export default NavLogo40;
